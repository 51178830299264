import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/MDXPageLayout.js";
import { graphql } from 'gatsby';
import PageBanner from '../../components/PageBanner';
import Button from '../../components/Button';
import HackavizFooter from '../../components/HackavizFooter';
export const pageQuery = graphql`
  query {
    allFile(filter: { name: { eq: "hackaviz23_description" } }) {
      nodes {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <PageBanner styleId={1} title={'Hackaviz 2023'} description={'Les données'} mdxType="PageBanner" />
    <section id="one">
      <div className="inner">
        <Button link={'https://github.com/ToulouseDataViz/Hackaviz2023'} text={'Voir la page Github dédiée aux données'} mdxType="Button" />
        <div className="blog-post-content" dangerouslySetInnerHTML={{
          __html: props.data.allFile.nodes[0].childMarkdownRemark.html
        }} />
        <HackavizFooter year={'2023'} mdxType="HackavizFooter" />
      </div>
    </section>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      